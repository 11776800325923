import padNumber from './numbers/pad-number'
import toFrenchFloat from './numbers/to-french-float'
import toThousand from './numbers/to-thousand'
import toCurrency from './numbers/to-currency'
import toFixed from './numbers/to-fixed'
import displayAmount from './numbers/display-amount'

import capitalize from './strings/capitalize'
import pluralize from './strings/pluralize'
import camelcase from './strings/camelcase'
import extractInitials from './strings/extract-initials'
import slugify from './strings/slugify'

import formatDate from './dates/format-date'
import getDaysDifference from './dates/get-days-difference'

import { ObjectId, uuid } from './misc/object-id'
import sleep from './misc/sleep'

export {
  padNumber,
  toFrenchFloat,
  toThousand,
  toCurrency,
  toFixed,
  displayAmount,

  pluralize, 
  capitalize, 
  extractInitials,
  camelcase,
  slugify,

  formatDate,
  getDaysDifference,

  uuid,
  ObjectId,
  sleep,
}

export default {
  padNumber,
  toFrenchFloat,
  toThousand,
  toCurrency,
  toFixed,
  displayAmount,

  pluralize, 
  capitalize, 
  extractInitials,
  camelcase,
  slugify,

  uuid,
  ObjectId,
  sleep,
}