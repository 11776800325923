const channels = {
  status: {
    pending: 'pending',
    accepted: 'accepted',
    rejected: 'rejected',
    close_automatically: 'close_automatically',
  },
}

export {
  channels, 
}

export default {
  channels, 
}