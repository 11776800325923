<template>
  <form 
    class="flex items-center w-full"
  >
    <div 
      class="fakeinput relative h-10 w-24/24 outline-none focus:outline-none flex items-center justify-between"
    >
      <img :src="aa" class="h-5">

      <div class="flex items-center w-18/24">
        <img :src="loupe" class="h-5">
        <img :src="cadenas" class="h-5">

        <p class="ml-2">
          gregoire beaulieu
        </p>
      </div>

      <img :src="reload" class="h-5 pr-3">
    </div>
  </form>
</template>

<script>
  import Aa from '@assets/images/aa.png'
  import Cadenas from '@assets/images/cadenas.png'
  import Reload from '@assets/images/reload.png'
  import Loupe from '@assets/images/loupe-alt.png'

  export default {
    setup (props, context) {
      function onSubmit (event) {
        context.emit('search', event)
      }

      function onSearching (event) {
        context.emit('searching', event)
      }
      
      return {
        onSubmit,
        onSearching,
        aa: Aa,
        cadenas: Cadenas,
        reload: Reload,
        loupe: Loupe,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fakeinput {
    border-radius: 12px;
    color: #a9a8ad;
    background: #E2E2E3;
    padding-left: 10px;
    letter-spacing: 0.2px;
    font-size: 16px;

    &::placeholder {
      color: #a9a8ad;
    }
  }

  button {
    color: #007aff;
  }
</style>