import { createStore } from 'vuex'
import json from '@assets/data/les-gouts-et-les-couleurs.json'
import router from '@core/router'

console.log(json.videos.youtube_suggestions)

let modules = {
  google: {
    namespaced: true,

    state () {
      return {
        results: json.results,
        latency: 0.25,
        delay: 0.5,
      }
    },

    mutations: {
      incrementLatency (state, amount = 0.1) {
        state.latency += amount
      },

      decrementLatency (state, amount = 0.1) {
        if (state.latency - amount >= 0) {
          state.latency += amount
        }
      },
    }
  },
  
  youtube: {
    namespaced: true,

    state () {
      return {
        videos: json.videos.search_videos,
        latency: 2.75,
        delay: 0.5,
      }
    },

    getters: {
      currentId () {
        return router?.currentRoute?.value?.query?.v || null
      },

      video (state, getters) {
        return getters.currentId ? state.videos.find(video => video._id === getters.currentId) : null
      },

      suggestions (state, getters) {
        return state.videos.filter(video => video._id !== getters.currentId)
      },
    },

    mutations: {
      incrementLatency (state, amount = 0.1) {
        state.latency += amount
      },

      decrementLatency (state, amount = 0.1) {
        if (state.latency - amount >= 0) {
          state.latency += amount
        }
      },
    }
  },
}

const store = createStore({
  modules: modules,
  strict: true,
  plugins: []
})

export default store 