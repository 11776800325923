<template>
  <router-view #default="{ Component }">
    <suspense>
      <template #default>
        <component :is="Component" />
      </template>

      <template #fallback>
        Chargement...
      </template>
    </suspense>
  </router-view>
</template>

<script>
  import { provide } from 'vue'
  import json from '@assets/data/les-gouts-et-les-couleurs.json'
  
  export default {
    setup () {
      provide('dataset', json)
    },
  }
</script>