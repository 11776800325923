<template>
  <form 
    @submit.prevent.stop="onSubmit"
    class="flex items-center w-full"
  >
    <input 
      ref="input"
      class="relative h-10 w-18/24 outline-none focus:outline-none"
      placeholder="Rechercher ou saisir un site"
      @input="onSearching"
    >

    <div class="w-6/24 flex items-center justify-end">
      <button class="font-medium text-lg">
        Annuler
      </button>
    </div>
  </form>
</template>

<script>
  import { onMounted, nextTick, ref } from 'vue'

  export default {
    setup (props, context) {
      const input = ref(null)

      function onSubmit (event) {
        context.emit('search', event)
      }

      function onSearching (event) {
        context.emit('searching', event)
      }

      onMounted(() => {
        nextTick(() => {
          input.value && input.value.focus()
        })
      })
      
      return {
        input,
        onSubmit,
        onSearching,
      }
    },
  }
</script>

<style lang="scss" scoped>
  input {
    border-radius: 12px;
    color: black;
    background: #e8e8ea;
    padding-left: 10px;
    letter-spacing: 0.2px;
    font-size: 16px;

    &::placeholder {
      color: #808080;
    }
  }

  button {
    color: #007aff;
  }
</style>