const notifications = {
  new_message: {
    title: {
      en: `{{ username }} sent you an invitation to chat with {{ pronoun }}.`,
    },

    description: {
      en: `Accept or refuse the invitation of {{ username }}.`,
    },
  },
}

export {
  notifications,
}

export default {
  notifications,
}