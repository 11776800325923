import { createRouter, createWebHistory } from 'vue-router'

const context = require.context('.', true, /\.router\.js$/)
const Routes = []

context.keys().forEach(key => Routes.push(context(key).default))

const router = createRouter({
  history: createWebHistory(process.env.APP_ROUTER_HISTORY_PATH || '/'),
  routes: Routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
