const smoker = {
  'smoker': 'smoker', 
  'sometimes': 'sometimes', 
  'quitting': 'quitting', 
  'non-smoker': 'non-smoker',
}

const children = {
  [`Have children`]: `Have children`,
  [`Want children`]: `Want children`,
  [`Don't want`]: `Don't want`,
  [`Haven't decided`]: `Haven't decided`,
}

const types = {
  social: 'social',
  pro: 'pro',
  commercial: 'commercial',
}

export {
  smoker,
  types,
  children,
}

export default {
  smoker,
  types,
  children,
}