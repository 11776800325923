<template>
  <div class="navline2">
    <ul class="nav-list">
      <li class="navlist-items">
        <a class="all flex items-center" @click.prevent.stop="noop">
          Tous
        </a>
      </li>
      <li class="navlist-items">
        <a @click.prevent.stop="noop">
          <i class="far fa-newspaper"></i>
          Actualités
        </a>
      </li>
      <li class="navlist-items">
        <a @click.prevent.stop="noop">
          <i class="fas fa-map-marker-alt"></i>
          Images
        </a>
      </li>
      <li class="navlist-items">
        <a @click.prevent.stop="noop">
          <i class="fab fa-youtube"></i>
          Vidéos
        </a>
      </li>
      <li class="navlist-items">
        <a @click.prevent.stop="noop">
          <i class="fas fa-tag"></i>
          Maps
        </a>
      </li>
      <li class="navlist-items more">
        <a @click.prevent.stop="noop">
          <i class="fas fa-ellipsis-v"></i>
          Plus
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    setup () {
      
      return {
        noop () {
          
        }
      }
    },
  }
</script>

<style lang="scss">

.navline2 {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  height: 40px;
}

.navline2 a {
  text-decoration: none;
  background: none;
  z-index: 0;
  font-size: 0.875rem;
  white-space: nowrap
}

.nav-list {
  display: flex;
  padding: 0px;
  // margin-left: 140px;
  list-style: none;
}

.navlist-items {
  padding: 10px 12px 2.5px 12px;
  position: relative;
  display: flex;
  align-items: center;

}
.navlist-items a {
  color: #5f6368;
  padding-left: 2px;
}

.all {
  color: black !important;
}
.all::after {
  content: '';
  display: block;
  height: 3px;
  background-color: black;
  position: absolute;
  bottom: -2px;
  width: 60%;
  /* transition: all ease-in-out 230ms; */
}


.more {
  // margin: 0 40px 0 40px;
}





</style>