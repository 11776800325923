export default {
  path: '/',
  name: 'search',
  
  components: {
    default: () => import(/* webpackChunkName: 'layout-router' */'@layouts/router'),
  },

  redirect: (to) => {
    let nextRoute = to.name === 'search' 
      ? { name: 'search-home' }
      : true

    return nextRoute
  },

  children: [{
    path: '',
    name: 'search-home',
  
    components: {
      default: () => import(/* webpackChunkName: 'google' */'@pages/search'),
    },
    
    children: [{
      path: '',
      name: 'search-favoris',
    
      components: {
        default: () => import(/* webpackChunkName: 'google' */'@pages/search/favoris'),
      },
    }, {
      path: 'q',
      name: 'search-autocomplete',
    
      components: {
        default: () => import(/* webpackChunkName: 'google' */'@pages/search/autocomplete'),
      },
    }]
  }]
}