<template>
  <header class="group z-10">
    <div id="topbar" class="bg-white">
      <div class="search-menu flex justify-center w-full">
        <div id="searchbar" type="text" class="max-w-18/24" style="box-shadow:0 1px 6px rgba(32,33,36,.13);">
          <button class="searchbarbutton w-3/24 flex justify-start items-center pl-2">
            <img :src="loupe" class="h-5">
          </button>

          <input 
            class="w-18/24" 
            type="text" 
            v-model="searchValue" 
          />

          <button class="searchbarbutton w-3/24 flex justify-end items-center pr-2">
            <img :src="croix" class="h-5">
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { computed, ref } from 'vue'
  import Croix from '@assets/images/croix.png'
  import Loupe from '@assets/images/loupe.png'

  export default {
    props: {
      search: {
        type: String, 
        default: '',
      } 
    },

    setup (props, context) {
      const searchModelValue = ref(`gregoire beaulieu`)

      const searchValue = computed({
        get () {
          return searchModelValue.value 
        },

        set (newValue) {
          searchModelValue.value = newValue 

          context.emit('update:search', newValue)
        }
      })
      
      return {
        searchValue,
        croix: Croix,
        loupe: Loupe,
      }
    },
  }
</script>

<style lang="scss">
  #header {
    position: relative;
    top: 0px;
    left: 0px;
    height: max-content;
  }

  .user-menu, .search-menu {
    position: relative;
    display: flex;
    align-items: center;
  }

  .user-menu {
    height: 100%;
    padding-right: 20px;

    a {
      color: white;
    }
  }

  #topbar {
    display: flex;
    height: 64px;
    width: 100%;
    align-items: flex-end;
    position: relative;
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  #searchbarimage {
    height: 30px;
    padding: 0px 28px 10px 30px;
    cursor: pointer;
  }


#searchbarmic {
  height: 45px;
  width: 35px;
  margin-left: auto;
  background-color: white;
}

#searchbarmic img {
  height: 25px;
}

.searchbarbutton {
  height: 100%;
  width: 20px;
  min-width: 20px !important;
  position: relative;
  margin-right: -2px;
  background-color: white;
}

.searchbarbutton svg {
  height: 25px;
  width: 35px;
  position: relative;
  left: -3px;
}

.searchbarbutton svg path {
  fill: #4285F4
}

#searchbar {
  width: 100%;
  height: 45px;
  border-radius: 100px;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  position: relative;
  bottom: 5px;
  overflow: hidden;
  display: flex;
  z-index: 100;
}

#searchbar:hover {
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}

#searchbar > input {
  height: 45px;
  border-style: none;
  font-size: 16px;
  line-height: 45px;
  padding-left: 20px;
  flex: 1;
}

#searchbar > input:focus {
  outline: none;
}

#searchbar button {
  border: none;
  cursor: pointer;
}

#searchbar button:focus {
  outline: none;
}

#boxesicon {
  width: 25px;
  height: 100%;
  /* height: 25px; */
  opacity: 0.6;
  background-size: 26px;
  position: relative;
  /* right: 130px;
  bottom: 14px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#optionsmenuactive::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #4285F4;
  left: 5px;
  bottom: 0px;
}

#optionsmenu2 {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  margin-left: 100px;
}

#optionsmenu2 li {
  padding: 0px 10px 15px 20px;
}

.gb_qa.gb_0d .gb_ae {
 margin-left:8px
}
#gb a.gb_4.gb_4.gb_ae,
#gb a.gb_3.gb_3.gb_ae {
 cursor:pointer
}
.gb_4.gb_ae:hover {
 background:#2b7de9;
 -moz-box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15);
 box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15)
}
.gb_4.gb_ae:focus,
.gb_4.gb_ae:hover:focus {
 background:#5094ed;
 -moz-box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15);
 box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15)
}
.gb_4.gb_ae:active {
 background:#63a0ef;
 -moz-box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15);
 box-shadow:0 1px 2px 0 rgba(66,133,244,0.3),0 1px 3px 1px rgba(66,133,244,0.15)
}
.gb_ae:not(.gb_3) {
 background:#1a73e8;
 border:1px solid transparent
}
.gb_qa.gb_ra .gb_ae {
 padding:9px 15px;
 min-width:80px
}
.gb_be {
 text-align:left
}
</style>