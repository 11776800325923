const roles = {
  superadmin: 'superadmin',
  admin: 'admin',
  user: 'user',
}

const genders = {
  man: 'Man', 
  woman: 'Woman', 
}

export {
  roles, 
  genders,
}

export default {
  roles, 
  genders,
}