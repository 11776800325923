export default {
  path: '/results',
  name: 'results',
  
  components: {
    default: () => import(/* webpackChunkName: 'layout-router' */'@layouts/router'),
  },

  redirect: (to) => {
    let nextRoute = to.name === 'results' 
      ? { name: 'results-home' }
      : true

    return nextRoute
  },

  children: [{
    path: '',
    name: 'results-home',
  
    components: {
      default: () => import(/* webpackChunkName: 'google' */'@pages/results'),
    },
  }]
}