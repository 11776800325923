const aac = 'audio/aac'
const abw = 'application/x-abiword'
const arc = 'application/octet-stream'
const avi = 'video/x-msvideo'
const azw = 'application/vnd.amazon.ebook'
const bin = 'application/octet-stream'
const octetStream = 'application/octet-stream'
const octet = octetStream
const bmp = 'OS/2 	image/bmp'
const bz = 'application/x-bzip'
const bz2 = 'application/x-bzip2'
const csh = 'application/x-csh'
const css = 'text/css'
const csv = 'text/csv'
const doc = 'application/msword'
const docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const eot = 'application/vnd.ms-fontobject'
const epub = 'application/epub+zip'
const gif = 'image/gif'
const htm = 'text/html'
const html = 'text/html'
const ico = 'image/x-icon'
const ics = 'text/calendar'
const jar = 'application/java-archive'
const jpeg = 'image/jpeg'
const jpg = 'image/jpeg'
const js = 'application/javascript'
const json = 'application/json'
const mid = 'audio/midi'
const midi = 'audio/midi'
const mpeg = 'video/mpeg'
const mpkg = 'application/vnd.apple.installer+xml'
const odp = 'application/vnd.oasis.opendocument.presentation'
const ods = 'application/vnd.oasis.opendocument.spreadsheet'
const odt = 'application/vnd.oasis.opendocument.text'
const oga = 'audio/ogg'
const ogv = 'video/ogg'
const ogx = 'application/ogg'
const otf = 'font/otf'
const png = 'image/png'
const pdf = 'application/pdf'
const ppt = 'application/vnd.ms-powerpoint'
const pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
const rar = 'application/x-rar-compressed'
const rtf = 'application/rtf'
const sh = 'application/x-sh'
const svg = 'image/svg+xml'
const swf = 'application/x-shockwave-flash'
const tar = 'application/x-tar'
const tif = 'image/tiff'
const tiff = 'image/tiff'
const ts = 'application/typescript'
const ttf = 'font/ttf'
const vsd = 'application/vnd.visio'
const wav = 'audio/x-wav'
const weba = 'audio/webm'
const webm = 'video/webm'
const webp = 'image/webp'
const woff = 'font/woff'
const woff2 = 'font/woff2'
const xhtml = 'application/xhtml+xml'
const xls = 'application/vnd.ms-excel'
const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const xml = 'application/xml'
const xul = 'application/vnd.mozilla.xul+xml'
const zip = 'application/zip'
const sevenz = 'application/x-7z-compressed'
const text = 'text/plain'
const plainText = 'text/plain'

const videos = [
  avi,
  mpeg,
  ogv,
  webm
]

const images = [
  bmp,
  gif,
  ico,
  jpeg,
  jpg,
  png,
  svg,
  tif,
  tiff,
  webp,
]

const audios = [
  aac,
  mid,
  midi,
  oga,
  wav,
  weba,
]

export {
  videos,
  images,
  audios,
  octetStream,
  octet,
  text,
  plainText,
  aac,
  abw,
  arc,
  avi,
  azw,
  bin,
  bmp,
  bz,
  bz2,
  csh,
  css,
  csv,
  doc,
  docx,
  eot,
  epub,
  gif,
  htm,
  html,
  ico,
  ics,
  jar,
  jpeg,
  jpg,
  js,
  json,
  mid,
  midi,
  mpeg,
  mpkg,
  odp,
  ods,
  odt,
  oga,
  ogv,
  ogx,
  otf,
  png,
  pdf,
  ppt,
  pptx,
  rar,
  rtf,
  sh,
  svg,
  swf,
  tar,
  tif,
  tiff,
  ts,
  ttf,
  vsd,
  wav,
  weba,
  webm,
  webp,
  woff,
  woff2,
  xhtml,
  xls,
  xlsx,
  xml,
  xul,
  zip,
  sevenz,
}

export default {
  videos,
  images,
  audios,
  octetStream,
  octet,
  text,
  plainText,
  aac,
  abw,
  arc,
  avi,
  azw,
  bin,
  bmp,
  bz,
  bz2,
  csh,
  css,
  csv,
  doc,
  docx,
  eot,
  epub,
  gif,
  htm,
  html,
  ico,
  ics,
  jar,
  jpeg,
  jpg,
  js,
  json,
  mid,
  midi,
  mpeg,
  mpkg,
  odp,
  ods,
  odt,
  oga,
  ogv,
  ogx,
  otf,
  png,
  pdf,
  ppt,
  pptx,
  rar,
  rtf,
  sh,
  svg,
  swf,
  tar,
  tif,
  tiff,
  ts,
  ttf,
  vsd,
  wav,
  weba,
  webm,
  webp,
  woff,
  woff2,
  xhtml,
  xls,
  xlsx,
  xml,
  xul,
  zip,
  sevenz,
}